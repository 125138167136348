import Cookies from 'js-cookie';
import { useFinalValue } from './react-utils';

export const LanguageKey = 'next_lng';

export const LanguageList = [
  {
    title: 'English',
    value: 'en',
  },
  {
    title: 'Русский',
    value: 'ru',
  },
];

export enum Language {
  en = 'en',
  zhHans = 'zhHans',
  zhHant = 'zhHant',
  ja = 'ja',
  ko = 'ko',
  fr = 'fr',
  de = 'de',
  es = 'es',
  ru = 'ru',
}

export const useLanguage = () => {
  const currentLanguage = useFinalValue(() => {
    const lng = getCurrentLanguage();
    return LanguageList.find((i) => i.value === lng) || LanguageList[0]!;
  });

  const changeLanguage = (value: string) => {
    Cookies.set(LanguageKey, value);
  };

  return { currentLanguage, changeLanguage };
};
export const getCurrentLanguage = () => {
  // 获取浏览器设置的首选语言
  // @ts-ignore 旧浏览器
  const userLanguage = navigator.language || navigator.userLanguage;

  // 处理语言字符串，提取主要语言部分
  const primaryLanguage = Cookies.get(LanguageKey) || userLanguage.split('-')[0];

  return primaryLanguage;
};
